
export default {
  name: "CopyToClipboardBtn",
  props: {
    text: {
      type: [Function, String, Number],
      required: true,
    },
    buttonText: {
      type: String,
      default: "",
    },
    buttonClasses: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    iconType: {
      type: String,
      default: "far fa-copy",
    },
    textOnlyButton: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      loading: false,
    }
  },
  mounted() {
    this.$addEventListener(
      "focusin",
      this.handleFocusin,
      this.$refs.copyToClipboard
    )
  },
  methods: {
    handleFocusin(e) {
      e.stopPropagation()
    },
    getProp(propName, fallback) {
      if (Object.prototype.hasOwnProperty.call(this.$attrs, propName)) {
        return this.$attrs[propName]
      } else {
        return fallback
      }
    },
    copyTextToClipboard() {
      try {
        this.loading = true
        let text
        if (typeof this.text === "function") {
          text = this.text()
        } else {
          text = this.text
        }

        this.$refs.copyToClipboard.value = text
        this.$refs.copyToClipboard.focus()
        this.$refs.copyToClipboard.select()

        document.execCommand("copy")
        this.$emit("copied")
        this.$notifications.success(this.$t("content.copy_success"))
      } catch (error) {
        this.$notifications.error({
          text: this.$t("content.copy_error"),
          error,
        })
      } finally {
        this.loading = false
      }
    },
  },
}
